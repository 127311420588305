import { t } from "i18next";
import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/header/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
import TempChat from "./Temp/TempChat";

const Chat: React.FC = () => {
	return (
		<>
			<Helmet>
				<title>
					{t("menuChat")} | {t("reCREWter")}
				</title>
			</Helmet>

			{/* wrapper start */}
			<div className="wrapper">
				{/* sidebar start */}
				<Sidebar />
				{/* sidebar end */}

				{/* main start */}
				<main>
					{/* header start */}
					<Header />
					{/* header end */}

					{/* inner-wrapper start */}
					<div className="inner-wrapper chat-wrapper">
						{/* Temporary Chat */}
						<TempChat />
					</div>
					{/* inner-wrapper end */}
				</main>
				{/* main end */}
			</div>
			{/* wrapper end */}
		</>
	);
};

export default Chat;
