import { useContext, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FeedbackMode } from "../config/Types/GeneralEnumDefinitions";
import { useTranslation } from "react-i18next";
import AuthenticationContext from "../components/auth/AuthenticationContext";

export default function FeedbackToast() {
	const { success, setSuccess, message, mode, setMode, duration } = useContext(
		AuthenticationContext
	);
	const { t } = useTranslation();

	const renderToastContent = (
		headerText: string,
		bodyText: string,
		iconClass: string
	) => (
		<div style={{ display: "flex", flexDirection: "column" }}>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<strong>{headerText}</strong>
				<small>{t("toast.now")}</small>
			</div>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					color: "white",
					marginTop: "8px",
				}}
			>
				<i className={iconClass}></i> {bodyText}
			</div>
		</div>
	);

	const showToast = () => {
		if (mode === FeedbackMode.Success && success) {
			toast.success(
				renderToastContent(t("toast.message"), message, "bi bi-check-circle"),
				{
					position: "top-right",
					autoClose: duration,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					onClose: () => setSuccess(false),
					theme: "colored",
					style: { backgroundColor: "#75a932" },
				}
			);
		} else if (mode === FeedbackMode.Error) {
			toast.error(
				renderToastContent(
					t("toast.error"),
					message,
					"bi bi-exclamation-triangle"
				),
				{
					position: "top-right",
					autoClose: duration,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					theme: "colored",
					onClose: () => setMode(FeedbackMode.Success),
					style: { backgroundColor: "#e53935" },
				}
			);
		}
	};

	useEffect(() => {
		showToast();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mode, success]);

	return <ToastContainer />;
}
