import Grid from "@mui/material/Grid2";
import React, { useContext, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import images from "../../assets/images";
import Header from "../../components/header/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
import { Helmet } from "react-helmet";
import { Button, IconButton, Tooltip } from "@mui/material";
import {
	getCompanyDetailsAPI,
	getCompanyHeaderAPI,
	getCompanyLogoAPI,
	isCompanyProfileCompletedAPI,
	submitCompanyDetailsAPI,
	submitFileAPI,
} from "../../core/apiFunctions";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { companyDetailsDTO } from "../../core/dto/user.models";
import { useSetState } from "../../utils/UseSetState";
import useFeedback from "../../utils/useFeedback";
import AuthenticationContext from "../../components/auth/AuthenticationContext";
import { getNameIdentifier, getRoleId } from "../../core/claimFunctions";
import {
	FileType,
	ImageType,
	UserRoles,
} from "../../config/Types/GeneralEnumDefinitions";
import { useProfileImage } from "../../contexts/ImageContext";
import { enumToString, formatUrlLink } from "../../core/helperConst";
import { Country, State } from "../../config/Types/PlaceEnumDefinitions";
import DropdownField from "../../components/common/DropdownField";
import TextField from "../../utils/TextField";
import { useLoading } from "../../contexts/LoadingContext";
import ModalContent from "../../utils/ModalContent";
import { ViewCompany } from "./ViewCompany";
import { useParams } from "react-router-dom";

const MyCompany = () => {
	const { setLoading } = useLoading();

	const companyNameKey = "company-name";
	const { t } = useTranslation();
	const { images1, setImage, saveImage, cancelImageChange } = useProfileImage();

	const { userId } = useParams<{ userId: string }>();

	const fieldErrorEmptyValues = {
		name: "",
		address: "",
		postcode: "",
		state: "",
		country: "",
		uid: "",
		city: "",
		email: "",
		phoneNumber: "",
		description: "",
	};

	const schema = yup.object({
		name: yup.string().required("NameRequired"),
		address: yup.string().required("AddressRequired"),
		postcode: yup.string().required("PostcodeRequired"),
		state: yup.string().required("StateRequired"),
		city: yup
			.string()
			.matches(/^[a-zA-ZäöüÄÖÜßéèêàçâôûîëïÿÉÈÊÀÇÂÔÛÎËÏŸ\s]+$/, "noNumbers")
			.required("CityRequired"),
		country: yup.string().required("CountryRequired"),
		uid: yup.string().required("UIDRequired"),
		email: yup.string().email("EmailNotValid").required("EmailRequired"),
		phoneNumber: yup.string().required("PhoneNumberRequired"),
		description: yup.string().required("DescriptionRequired"),
	});

	const [isEditing, setIsEditing] = useState<boolean>(false);
	const [companyDetailsInital, setCompanyDetailsInitial] =
		useState<companyDetailsDTO>();
	const [companyDetails, setCompanyDetails] = useState<companyDetailsDTO>();
	const [errors, setErrors] = useState<string[]>([]);
	const [fieldErrors, setFieldErrors, getFieldErrors] = useSetState(
		fieldErrorEmptyValues
	);
	const { showSuccess } = useFeedback();
	const { claims } = useContext(AuthenticationContext);

	const [missingData, setMissingData] = useState(false);
	const [companyProfileCompleted, setCompanyProfileCompleted] = useState(false);
	const [showCongratsModal, setShowCongratsModal] = useState(false);

	const congratsModalShownKey =
		"congratsModalShown_" + getNameIdentifier(claims);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		const fetchCompanyDetails = async () => {
			setLoading(true);
			try {
				if (userId) {
					// Fetch company details for the given userId
					const response = await getCompanyDetailsAPI(userId);
					setCompanyDetailsInitial(response.data);
					setCompanyDetails(response.data);

					// Fetch company images
					const headerImageResponse = await getCompanyHeaderAPI(
						response.data.id
					);
					if (headerImageResponse && headerImageResponse.data) {
						setCompanyHeader(URL.createObjectURL(headerImageResponse.data));
					} else {
						setCompanyHeader(images.CompanyHeaderDefault); // Use a default image if none is found
					}

					const logoResponse = await getCompanyLogoAPI(response.data.id);
					if (logoResponse && logoResponse.data) {
						setCompanyLogo(URL.createObjectURL(logoResponse.data));
					} else {
						setCompanyLogo(images.CompanyLogoDefault); // Use a default image if none is found
					}

					setIsEditing(false); // Disable editing when viewing another company
				} else {
					const role = getRoleId(claims);
					if (role === UserRoles.Recrewter) {
						// Fetch logged-in user's company details
						const response = await getCompanyDetailsAPI();
						setCompanyDetailsInitial(response.data);
						setCompanyDetails(response.data);

						// Use images from useProfileImage context
						// setHeaderImage(images1[ImageType.CompanyHeader]);
						// setLogoImage(images1[ImageType.CompanyLogo]);

						isCompanyProfileCompletedAPI()
							.then((response) => {
								setCompanyProfileCompleted(response.data);
								setIsEditing(!response.data);
								setMissingData(!response.data);
							})
							.catch((error) => {
								setErrors(error.response.data);
							});
					}
				}
			} catch (error: any) {
				setErrors(error.response?.data || [error.message]);
			} finally {
				setLoading(false);
			}
		};

		fetchCompanyDetails();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userId, claims]);

	const validateNestedSchema = async () => {
		const errors = await schema
			.validate(companyDetails, { abortEarly: false })
			.catch((err) => {
				return err;
			});

		if (errors instanceof yup.ValidationError) {
			const validationErrors: any = {};
			errors.inner.forEach((error: any) => {
				validationErrors[error.path] = error.message;
			});
			setFieldErrors(validationErrors);
		}
	};

	const handleOnChange = (e: any) => {
		if (!isEditing) {
			setIsEditing(true);
		}

		const { name, value } = e.target;
		if (name in fieldErrors) {
			schema
				.validateAt(name, { [name]: value })
				.then(() => {
					setFieldErrors({ ...fieldErrors, [name]: "" });
				})
				.catch((err) => {
					setFieldErrors({ ...fieldErrors, [name]: err.errors[0] });
				});
		}

		if (name !== "country")
			setCompanyDetails({ ...companyDetails!, [name]: value });
		else setCompanyDetails({ ...companyDetails!, [name]: value, state: 0 });
	};

	const setOrigValues = () => {
		setCompanyDetails(companyDetailsInital);
		setIsEditing(false);
		setErrors([]);
		setFieldErrors(fieldErrorEmptyValues);
		window.scrollTo(0, 0);
	};

	const submit = async () => {
		try {
			await validateNestedSchema();
			if (
				errors.length === 0 &&
				Object.values(await getFieldErrors()).every((x) => x === "")
			) {
				setLoading(true);
				isEditing &&
					(await submitCompanyDetailsAPI(companyDetails!).finally(() =>
						setLoading(false)
					));
				setCompanyDetailsInitial(companyDetails);

				localStorage.setItem(companyNameKey, companyDetails!.name);

				setIsEditing(false);
				showSuccess(t("successfullySaved"));

				setCompanyProfileCompleted(true);
				setMissingData(false);
				showCongratsAndClearMissingData();
				window.scrollTo(0, 0);
			}
		} catch (error: any) {
			setErrors(error.response.data);
		}
	};

	// ***********

	const [profileFile, setProfileFile] = useState<File>();
	const [headerFile, setHeaderFile] = useState<File>();
	const [companyHeader, setCompanyHeader] = useState<string>(
		images.CompanyHeaderDefault
	);
	const [companyLogo, setCompanyLogo] = useState<string>(
		images.CompanyLogoDefault
	);
	const [newHeaderPhoto, setNewHeaderPhoto] = useState<boolean>(false);
	const [newProfilePhoto, setNewProfilePhoto] = useState<boolean>(false);
	const [isImgEditing, setIsImgEditing] = useState<boolean>(false);

	const openUploadDialog = (
		setNewImageFlag: (status: boolean) => void,
		setFile: (file: File) => void,
		setPhoto: (photo: string) => void,
		imageType: ImageType
	) => {
		const input = document.createElement("input");
		input.type = "file";
		input.accept = "image/*";
		input.onchange = (e: any) => {
			const file = e.target.files[0];
			if (validate(file)) {
				setIsImgEditing(true);
				const url = URL.createObjectURL(file);
				setPhoto(url);
				setFile(file);
				setNewImageFlag(true);
				setImage(imageType, url);
			}
		};
		input.click();
	};

	const validate = (file: File) => {
		const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
		if (!isJpgOrPng) {
			alert("You can only upload JPG/PNG file!");
			return false;
		}

		const isLt25M = file.size / 1024 / 1024 < 25;
		if (!isLt25M) {
			alert("Image must be smaller than 25MB!");
			return false;
		}
		return isJpgOrPng && isLt25M;
	};

	const uploadImage = () => {
		const promises: Promise<any>[] = [];
		setLoading(true);

		if (newHeaderPhoto) {
			const fileUploadDto = { file: headerFile!, type: FileType.CompanyHeader };
			promises.push(
				submitFileAPI(fileUploadDto).then(() => {
					saveImage(ImageType.CompanyHeader);
					setNewHeaderPhoto(false);
				})
			);
		}

		if (newProfilePhoto) {
			const fileUploadDto = {
				file: profileFile!,
				type: FileType.CompanyProfilePicture,
			};
			promises.push(
				submitFileAPI(fileUploadDto).then(() => {
					saveImage(ImageType.CompanyLogo);
					setNewProfilePhoto(false);
				})
			);
		}

		Promise.all(promises)
			.then(() => {
				setIsImgEditing(false);
				showSuccess(t("successfullySaved"));
			})
			.catch((error) => setErrors([error.message]))
			.finally(() => setLoading(false));
	};

	const showCongratsAndClearMissingData = () => {
		setShowCongratsModal(!companyProfileCompleted);
	};

	const resetToOriginalImg = () => {
		cancelImageChange(ImageType.CompanyHeader);
		cancelImageChange(ImageType.CompanyLogo);
		setNewHeaderPhoto(false);
		setNewProfilePhoto(false);
		setIsImgEditing(false);
	};

	return (
		<>
			<Helmet>
				<title>
					{t("companyProfile")} | {t("reCREWter")}
				</title>
			</Helmet>

			{/* wrapper start */}
			<div className="wrapper">
				{/* sidebar start */}
				<Sidebar />
				{/* sidebar end */}

				{/* main start */}
				<main>
					{/* header start */}
					<Header />
					{/* header end */}

					{/* profile wrapper start */}
					<div className="inner-wrapper profile-wrapper company-profile">
						{/* left start */}
						<div className="left">
							{/* user info start  */}
							<div className="user-info card">
								{/* Header Image */}
								<Tooltip
									arrow
									title={t("recommendedMinHeader")}
									disableHoverListener={!isEditing}
									classes={{ popper: "resolution-tooltip" }}
								>
									<div
										className={`thumbnail ${isEditing ? "backgroundImg" : ""}`}
										style={{
											backgroundImage: `url(${
												userId
													? companyHeader
													: images1[ImageType.CompanyHeader]
											})`,
										}}
										onClick={() =>
											!userId &&
											isEditing &&
											openUploadDialog(
												setNewHeaderPhoto,
												setHeaderFile,
												setImage.bind(null, ImageType.CompanyHeader),
												ImageType.CompanyHeader
											)
										}
									></div>
								</Tooltip>
								{/* content start */}
								<div className="content">
									{/* Company Logo */}
									<Tooltip
										arrow
										title={t("recommendedProfile")}
										disableHoverListener={!isEditing}
										classes={{ popper: "profile-img-tooltip" }}
									>
										<img
											className={isEditing ? "profile-img" : ""}
											src={
												userId ? companyLogo : images1[ImageType.CompanyLogo]
											}
											alt={"Company Logo"}
											onClick={() =>
												!userId &&
												isEditing &&
												openUploadDialog(
													setNewProfilePhoto,
													setProfileFile,
													setImage.bind(null, ImageType.CompanyLogo),
													ImageType.CompanyLogo
												)
											}
										/>
									</Tooltip>
									{/* user details start */}
									<div className="user-details">
										<div className="top">
											<div className="user-name">
												<h4>{companyDetailsInital?.name}</h4>
												<p>{t("company")}</p>
												{companyDetailsInital?.country.toString() && (
													<span className="location">
														<img src={images.Location} alt="Location" />
														<span>{`${companyDetailsInital.address ? companyDetailsInital.address : ""} ${
															companyDetailsInital?.postcode
																? `, ${companyDetailsInital?.postcode}`
																: ""
														} ${companyDetailsInital?.city ? `, ${companyDetailsInital?.city}` : ""}${
															companyDetailsInital?.state
																? `, ${t(
																		enumToString(
																			State,
																			companyDetailsInital?.state
																		)
																	)}`
																: ""
														} ${
															companyDetailsInital?.country
																? `, ${t(
																		enumToString(
																			Country,
																			companyDetailsInital?.country
																		)
																	)}`
																: ""
														}`}</span>
													</span>
												)}
											</div>
											{!userId && !isEditing && (
												<Button
													variant="outlined"
													size="small"
													title={t("editProfile")}
													onClick={() => {
														setIsEditing(true);
														window.scrollTo(0, 0);
													}}
													className="editButton"
												>
													<img src={images.Edit} alt="Edit" />
													<span>{t("editProfile")}</span>
												</Button>
											)}
											{isImgEditing && !userId && (
												<Grid
													size={{ sm: 6, md: 4, xs: 12 }}
													sx={{
														display: "flex",
														justifyContent: "flex-end",
														gap: "15px",
													}}
													className="buttonGrid"
												>
													<Button
														type="button"
														onClick={resetToOriginalImg}
														variant="outlined"
														disabled={!isImgEditing}
													>
														{t("revertImages")}
													</Button>
													<Button
														type="button"
														onClick={uploadImage}
														color="primary"
														variant="contained"
														disabled={!isImgEditing}
													>
														{t("saveImages")}
													</Button>
												</Grid>
											)}
										</div>
										<div className="bottom">
											<div className="social-links">
												{companyDetails?.xing && (
													<RouterLink
														to={formatUrlLink(companyDetails?.xing)}
														target="_blank"
													>
														<IconButton title="Xing">
															<img src={images.Xing} alt="Xing" />
														</IconButton>
													</RouterLink>
												)}
												{companyDetails?.linkedIn && (
													<RouterLink
														to={formatUrlLink(companyDetails?.linkedIn)}
														target="_blank"
													>
														<IconButton title={t("linkedin")}>
															<img src={images.Linkedin2} alt={t("linkedin")} />
														</IconButton>
													</RouterLink>
												)}
												{companyDetails?.website && (
													<RouterLink
														to={formatUrlLink(companyDetails?.website)}
														target="_blank"
													>
														<IconButton title={t("website")}>
															<img src={images.Globe} alt={t("website")} />
														</IconButton>
													</RouterLink>
												)}
												{companyDetails?.youTubeUrl && (
													<RouterLink
														to={formatUrlLink(companyDetails?.youTubeUrl)}
														target="_blank"
													>
														<IconButton title={t("youtube")}>
															<img src={images.YouTube} alt={t("youtube")} />
														</IconButton>
													</RouterLink>
												)}
											</div>
										</div>
									</div>
									{/* user details end */}
								</div>
								{/* content end */}
							</div>
							{/* user info end  */}

							{isEditing && !userId ? (
								<>
									<div className="card">
										<h6>{t("companyInformation")}</h6>
										<Grid container spacing={{ xs: 3, sm: 4, lg: 5 }}>
											<Grid size={{ sm: 6, md: 4, xs: 12 }}>
												<TextField
													displayName="companyName"
													field="name"
													placeholder={t("placeholderCompanyName")}
													value={companyDetails?.name}
													formFloating={true}
													onChange={handleOnChange}
													validationMessage={fieldErrors.name}
													isMandatory={true}
												/>
											</Grid>
											<Grid size={{ sm: 6, md: 4, xs: 12 }}>
												<TextField
													displayName="companyAddress"
													field="address"
													placeholder={t("companyPlaceholderAddress")}
													value={companyDetails?.address}
													formFloating={true}
													onChange={handleOnChange}
													validationMessage={fieldErrors.address}
													isMandatory={true}
												/>
											</Grid>
											<Grid size={{ sm: 6, md: 4, xs: 12 }}>
												<TextField
													field="postcode"
													value={companyDetails?.postcode}
													displayName="postcode"
													placeholder={t("placeholderPostCode")}
													formFloating={true}
													onChange={handleOnChange}
													validationMessage={fieldErrors.postcode}
													isMandatory={true}
												/>
											</Grid>
											<Grid size={{ sm: 6, md: 4, xs: 12 }}>
												<TextField
													displayName="city"
													field="city"
													placeholder={t("placeholderCity")}
													value={companyDetails?.city}
													formFloating={true}
													onChange={handleOnChange}
													validationMessage={fieldErrors.city}
													isMandatory={true}
												/>
											</Grid>
											<Grid size={{ sm: 6, md: 4, xs: 12 }}>
												<DropdownField
													displayName={"country"}
													field={"country"}
													onChange={handleOnChange}
													enumType={Country}
													selected={companyDetails?.country}
													validationMessage={fieldErrors.country}
												></DropdownField>
											</Grid>
											<Grid size={{ sm: 6, md: 4, xs: 12 }}>
												{companyDetails?.country === Country.Switzerland ? (
													<DropdownField
														displayName={"canton"}
														field="state"
														enumType={State}
														selected={companyDetails?.state!}
														onChange={handleOnChange}
														disabled={
															companyDetails?.country !== Country.Switzerland
														}
														index={21600}
														indexSize={27}
														validationMessage={fieldErrors.state}
													/>
												) : companyDetails?.country === Country.Germany ? (
													<DropdownField
														displayName={"state"}
														field="state"
														enumType={State}
														selected={companyDetails?.state!}
														onChange={handleOnChange}
														disabled={
															companyDetails?.country !== Country.Germany
														}
														index={8400}
														indexSize={17}
														validationMessage={fieldErrors.state}
													/>
												) : companyDetails?.country === Country.Austria ? (
													<DropdownField
														displayName={"state"}
														field="state"
														enumType={State}
														selected={companyDetails?.state!}
														onChange={handleOnChange}
														disabled={
															companyDetails?.country !== Country.Austria
														}
														index={1500}
														indexSize={10}
														validationMessage={fieldErrors.state}
													/>
												) : (
													<DropdownField
														displayName={t("state")}
														field="state"
														enumType={State}
														selected={0}
														onChange={handleOnChange}
														disabled={true}
													/>
												)}
											</Grid>
											<Grid size={{ sm: 6, md: 4, xs: 12 }}>
												<TextField
													displayName="uid"
													field="uid"
													placeholder={t("placeholderUid")}
													value={companyDetails?.uid}
													formFloating={true}
													onChange={handleOnChange}
													validationMessage={fieldErrors.uid}
													isMandatory={true}
												/>
											</Grid>
											<Grid size={{ sm: 6, md: 4, xs: 12 }}>
												<TextField
													field="email"
													displayName="eMailCompany"
													placeholder={t("placeholderEmail")}
													value={companyDetails?.email}
													formFloating={true}
													onChange={handleOnChange}
													validationMessage={fieldErrors.email}
													isMandatory={true}
												/>
											</Grid>
											<Grid size={{ sm: 6, md: 4, xs: 12 }}>
												<TextField
													displayName="companyPhone"
													field="phoneNumber"
													placeholder={t("placeholderPhoneNumber")}
													value={companyDetails?.phoneNumber}
													formFloating={true}
													onChange={handleOnChange}
													validationMessage={fieldErrors.phoneNumber}
													isMandatory={true}
												/>
											</Grid>
											<Grid size={{ xs: 12 }}>
												<TextField
													displayName="description"
													field="description"
													value={companyDetails?.description}
													as="textarea"
													formFloating={false}
													onChange={handleOnChange}
													placeholder={t("companyDescriptionPlaceholder")}
													isMultiline
													rows={5}
													validationMessage={fieldErrors.description}
													isMandatory={true}
												/>
											</Grid>
										</Grid>
									</div>

									<div className="card">
										<h6>{t("socialInformation")}</h6>
										<Grid container spacing={{ xs: 3, sm: 4, lg: 5 }}>
											<Grid size={{ sm: 6, md: 4, xs: 12 }}>
												<TextField
													displayName="website"
													field="website"
													placeholder={t("placeholderWebsite")}
													value={companyDetails?.website}
													formFloating={true}
													onChange={handleOnChange}
												/>
											</Grid>
											<Grid size={{ sm: 6, md: 4, xs: 12 }}>
												<TextField
													displayName="linkedIn"
													field="linkedIn"
													placeholder={t("placeholderLinkedIn")}
													value={companyDetails?.linkedIn}
													formFloating={true}
													onChange={handleOnChange}
												/>
											</Grid>
											<Grid size={{ sm: 6, md: 4, xs: 12 }}>
												<TextField
													displayName="xing"
													field="xing"
													placeholder={t("placeholderXing")}
													value={companyDetails?.xing}
													formFloating={true}
													onChange={handleOnChange}
												/>
											</Grid>
											<Grid size={{ sm: 6, md: 4, xs: 12 }}>
												<TextField
													displayName="youtube"
													field="youTubeUrl"
													placeholder={t("placeholderYoutube")}
													value={companyDetails?.youTubeUrl}
													formFloating={true}
													onChange={handleOnChange}
												/>
											</Grid>
										</Grid>
									</div>

									<div className="actionButtonCard">
										<Grid
											size={{ sm: 6, md: 4, xs: 12 }}
											sx={{
												display: "flex",
												justifyContent: "flex-end",
												gap: "15px",
											}}
											className="buttonGrid"
										>
											{companyProfileCompleted && (
												<Button
													type="button"
													onClick={setOrigValues}
													variant="outlined"
												>
													{t("cancel")}
												</Button>
											)}
											<Button
												type="button"
												onClick={submit}
												color="primary"
												variant="contained"
											>
												{t("save")}
											</Button>
										</Grid>
									</div>
								</>
							) : (
								<ViewCompany companyDetails={companyDetails}></ViewCompany>
							)}
						</div>
						{/* left end */}
					</div>
					{/* profile wrapper end */}
				</main>
				{/* main end */}
			</div>
			{/* wrapper end */}
			{!companyProfileCompleted && !userId && (
				<ModalContent
					size="lg"
					show={missingData}
					children={<>{t("justCompanyProfileLeft")}</>}
					onHideCallbackSecondary={() => setMissingData(false)}
					onHideCallbackPrimary={() => {
						setMissingData(false);
					}}
					title={t("missingData")}
					primaryButton={t("ok")}
					onClose={() => setMissingData(false)}
				/>
			)}
			{showCongratsModal && !userId && (
				<ModalContent
					size="lg"
					show={showCongratsModal}
					children={<>{t("congratulationsProfileCompleted")}</>}
					onHideCallbackPrimary={() => setShowCongratsModal(false)}
					title={t("congratulations")}
					primaryButton={t("ok")}
				/>
			)}
		</>
	);
};

export default MyCompany;
