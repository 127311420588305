import {
	Button,
	FormControlLabel,
	IconButton,
	Link,
	Switch,
	ToggleButton,
	ToggleButtonGroup,
	Tooltip,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import images from "../../assets/images";
import Sidebar from "../../components/Sidebar/Sidebar";
import Header from "../../components/header/Header";
import { EditMyProfile } from "../../components/myProfile/EditMyProfile";
import { ViewProfile } from "../../components/myProfile/ViewProfile";
import {
	FileType,
	ImageType,
	UserRoles,
} from "../../config/Types/GeneralEnumDefinitions";
import { Country, State } from "../../config/Types/PlaceEnumDefinitions";
import {
	downloadFileAPI,
	getAboutMeAPI,
	getCertificatesAPI,
	getDreamJobAPI,
	getEducationsAPI,
	getLanguageSkillsAPI,
	getRecrewterDetailsAPI,
	getSkillsAPI,
	getUserDetailsAPI,
	getWorkExperienceAPI,
	isCompanyProfileCompletedAPI,
	isProfileCompletedAPI,
	submitFileAPI,
} from "../../core/apiFunctions";
import {
	checkRole,
	checkRoles,
	getEmail,
	getName,
	getNameIdentifier,
	getRoleId,
} from "../../core/claimFunctions";
import { internalJobDTO } from "../../core/dto/dto.models";
import {
	aboutMeDTO,
	certificateDTO,
	educationDTO,
	idealCandidateDTO,
	languageSkillDTO,
	skillDTO,
	userDetailsDTO,
	workExperienceDTO,
} from "../../core/dto/user.models";
import { enumToString, formatUrlLink } from "../../core/helperConst";
import ModalContent from "../../utils/ModalContent";
import { useTranslation } from "react-i18next";
import { useProfileImage } from "../../contexts/ImageContext";
import AuthenticationContext from "../../components/auth/AuthenticationContext";
import { MatchingBaseDetailCard } from "../../components/myProfile/MatchingBaseDetailCard";
import { useLoading } from "../../contexts/LoadingContext";
import Emitter from "../../utils/emitter";
import useFeedback from "../../utils/useFeedback";

const MyProfile = () => {
	const { images1, setImage, saveImage, cancelImageChange } = useProfileImage();
	const { t } = useTranslation();
	const { claims } = useContext(AuthenticationContext);
	const [alignment, setAlignment] = React.useState<string | null>(
		"basicInformation"
	);
	const congratsModalShownKey =
		"congratsModalShown_" + getNameIdentifier(claims);

	const { setLoading } = useLoading();
	const { showSuccess } = useFeedback();
	const navigate = useNavigate();

	const [profileCompleted, setProfileCompleted] = useState(false);
	const [companyProfileCompleted, setCompanyProfileCompleted] = useState(false);
	const [showCongratsModal, setShowCongratsModal] = useState(false);
	const [showCompleteOtherProfileModal, setShowCompleteOtherProfileModal] =
		useState(false);
	const [viewVideoModal, setViewVideoModal] = useState(false);
	const [missingData, setMissingData] = useState(false);

	const [role] = useState<UserRoles>(getRoleId(claims) ?? 0);

	const [isEditing, setIsEditing] = useState<boolean>(false);
	const [isImgEditing, setImgEditing] = useState<boolean>(false);
	const [userDetailsInitial, setUserDetailsInitial] =
		useState<userDetailsDTO>();
	const [userDetails, setUserDetails] = useState<userDetailsDTO>();
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [errors, setErrors] = useState<string[]>([]);

	const [dreamJobInitial, setDreamJobInitial] = useState<internalJobDTO>(
		{} as internalJobDTO
	);
	const [dreamJob, setDreamJob] = useState<internalJobDTO>(
		{} as internalJobDTO
	);

	const [aboutMeInital, setAboutMeInitial] = useState<aboutMeDTO>();
	const [aboutMe, setAboutMe] = useState<aboutMeDTO>();

	const [videoSource, setVideoSource] = useState<string | undefined>(undefined);

	const [languageSkillsInitial, setLanguageSkillsInitial] = useState<
		languageSkillDTO[]
	>([]);
	const [languageSkills, setLanguageSkills] = useState<languageSkillDTO[]>([]);

	const [skillsInitial, setSkillsInitial] = useState<skillDTO[]>([]);
	const [skills, setSkills] = useState<skillDTO[]>([]);
	const [workExperiencesInitial, setWorkExperiencesInitial] = useState<
		workExperienceDTO[]
	>([]);
	const [workExperiences, setWorkExperiences] = useState<workExperienceDTO[]>(
		[]
	);
	const [educationsInitial, setEducationsInitial] = useState<educationDTO[]>(
		[]
	);
	const [educations, setEducations] = useState<educationDTO[]>([]);
	const [certificatesInitial, setCertificatesInitial] = useState<
		certificateDTO[]
	>([]);
	const [certificates, setCertificates] = useState<certificateDTO[]>([]);
	const [idealCandidate, setIdealCandidate] = useState<idealCandidateDTO>(
		{} as idealCandidateDTO
	);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [initialIdealCandidate, setInitialIdealCandidate] =
		useState<idealCandidateDTO>({} as idealCandidateDTO);

	const [scrollOffset, setScrollOffset] = useState(0);
	const toggleRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		Emitter.on("toggleProfileVisibility", () => {
			getUserDetailsAPI()
				.then((response) => {
					setUserDetailsInitial(response.data);
					setUserDetails(response.data);
					if (toggleRef.current) {
						toggleRef.current.checked = false;
					}
				})
				.catch((error) => {
					setErrors(error.response.data);
				});
		});
	}, []);

	useEffect(() => {
		setLoading(true);
		getUserDetailsAPI()
			.then((response) => {
				setUserDetailsInitial(response.data);
				setUserDetails(response.data);
				checkProfileCompletionStatus();
			})
			.catch((error) => {
				setErrors(error.response.data);
			});

		if (role === UserRoles.Employee) {
			//dreamJobApi
			getDreamJobAPI()
				.then((response) => {
					setDreamJob(response.data);
					setDreamJobInitial(response.data);
				})
				.catch((error) => {
					setErrors(error.response.data);
				});

			// aboutMeApi
			getAboutMeAPI()
				.then((response) => {
					setAboutMeInitial(response.data);
					setAboutMe(response.data);

					// Download video if it exists
					if (response.data.filePath && response.data.filePath !== "..") {
						downloadFileAPI(response.data.filePath).then((response) => {
							// Create local object URL from blob
							const url = URL.createObjectURL(response.data);
							// Set video source to local object URL
							setVideoSource(url);
						});
					}
				})
				.catch((error) => {
					setErrors([error]);
				});

			// LanguageSkillsAPI
			getLanguageSkillsAPI()
				.then((response) => {
					setLanguageSkillsInitial(response.data);
					setLanguageSkills(response.data);
				})
				.catch((error) => {
					setErrors(error.response.data);
				});

			// SkillsAPI
			getSkillsAPI()
				.then((response) => {
					setSkillsInitial(response.data);
					setSkills(response.data);
				})
				.catch((error) => {
					setErrors(error.response.data);
				});

			//workExperience
			getWorkExperienceAPI()
				.then((response) => {
					setWorkExperiencesInitial(response.data);
					setWorkExperiences(response.data);
				})
				.catch((error) => {
					setErrors(error.response.data);
				});

			//education
			getEducationsAPI()
				.then((response) => {
					setEducationsInitial(response.data);
					setEducations(response.data);
				})
				.catch((error) => {
					setErrors(error.response.data);
				});

			//certificate
			getCertificatesAPI()
				.then((response) => {
					setCertificatesInitial(response.data);
					setCertificates(response.data);
				})
				.catch((error) => {
					setErrors(error.response.data);
				})
				.finally(() => {
					setLoading(false);
				});
		}

		//ideal candidate Api

		if (role === UserRoles.Recrewter) {
			getRecrewterDetailsAPI()
				.then((response) => {
					setInitialIdealCandidate(
						response.data.idealCandidate ?? ({} as idealCandidateDTO)
					);
					setIdealCandidate(
						response.data.idealCandidate ?? ({} as idealCandidateDTO)
					);
				})
				.catch((error) => console.log(error))
				.finally(() => {
					setLoading(false);
				});
		}

		const handleResize = () => {
			const screenWidth = window.innerWidth;
			if (screenWidth < 768) {
				setScrollOffset(180);
			} else if (screenWidth < 1440) {
				setScrollOffset(110);
			} else if (screenWidth < 1600) {
				setScrollOffset(120);
			} else {
				setScrollOffset(140);
			}
		};
		window.addEventListener("resize", handleResize);
		handleResize(); // Initialize on mount
		return () => {
			window.removeEventListener("resize", handleResize);
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [role]);

	//scroll logic
	const handleScroll = (id: string) => {
		const element = document.getElementById(id);
		if (element) {
			const topPosition =
				element.getBoundingClientRect().top + window.scrollY - scrollOffset; // Adjusting by 100 pixels
			window.scrollTo({
				top: topPosition,
				behavior: "smooth", // Smooth scrolling
			});
		}
	};

	const handleUserProfileCompleted = () => {
		setProfileCompleted(true);

		if (role === UserRoles.Recrewter) {
			if (companyProfileCompleted) {
				showCongratsAndClearMissingData();
			} else {
				// Switch to company profile tab if personal profile is completed but company profile is not
				navigate("/company");
			}
		} else if (role === UserRoles.Employee) {
			// Show congrats when employee completes their profile
			showCongratsAndClearMissingData();
		}
	};

	const showCongratsAndClearMissingData = () => {
		setShowCongratsModal(!profileCompleted);
		setMissingData(false);
	};

	const handleAlignment = (
		event: React.MouseEvent<HTMLElement>,
		newAlignment: string | null
	) => {
		handleScroll(newAlignment ?? "");
		setAlignment(newAlignment);
	};

	const checkProfileCompletionStatus = async () => {
		const profileCompleteResponse = await isProfileCompletedAPI();
		setProfileCompleted(profileCompleteResponse.data);
		setIsEditing(!profileCompleteResponse.data);

		if (role === UserRoles.Recrewter) {
			const companyProfileCompleteResponse =
				await isCompanyProfileCompletedAPI();
			setCompanyProfileCompleted(companyProfileCompleteResponse.data);
		}

		const missing =
			!profileCompleted ||
			(!companyProfileCompleted && role === UserRoles.Recrewter);
		setMissingData(missing);
	};

	// upload profile image
	const [newProfilePhoto, setNewProfilePhoto] = useState<boolean>(false);
	const [profileFile, setProfileFile] = useState<File>();
	const [headerFile, setHeaderFile] = useState<File>();
	const [newHeaderPhoto, setNewHeaderPhoto] = useState<boolean>(false);

	const validate = (file: File) => {
		const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
		if (!isJpgOrPng) {
			alert("You can only upload JPG/PNG file!");
			return false;
		}

		const isLt25M = file.size / 1024 / 1024 < 25;
		if (!isLt25M) {
			alert("Image must be smaller than 25MB!");
			return false;
		}
		return isJpgOrPng && isLt25M;
	};
	const openUploadDialog = (
		setNewImageFlag: (status: boolean) => void,
		setFile: (file: File) => void,
		setPhoto: (photo: string) => void,
		imageType: ImageType
	) => {
		const input = document.createElement("input");
		input.type = "file";
		input.accept = "image/*";
		input.onchange = (e: any) => {
			const file = e.target.files[0];
			if (validate(file)) {
				setImgEditing(true);
				const url = URL.createObjectURL(file);
				setPhoto(url);
				setFile(file);
				setNewImageFlag(true);
				setImage(imageType, url);
			}
		};
		input.click();
	};
	const uploadUserPhotos = () => {
		setLoading(true);
		const promises: Promise<any>[] = [];

		if (newHeaderPhoto) {
			const fileUploadDto = { file: headerFile!, type: FileType.UserHeader };
			promises.push(
				submitFileAPI(fileUploadDto).then(() => {
					saveImage(ImageType.HeaderImage);
					setNewHeaderPhoto(false);
				})
			);
		}

		if (newProfilePhoto) {
			const fileUploadDto = {
				file: profileFile!,
				type: FileType.UserProfilePicture,
			};
			promises.push(
				submitFileAPI(fileUploadDto).then(() => {
					saveImage(ImageType.ProfileImage);
					setNewProfilePhoto(false);
				})
			);
		}

		Promise.all(promises)
			.then(() => {
				setImgEditing(false);
				showSuccess(t("successfullySaved"));
			})
			.catch((error) => setErrors([error.message]))
			.finally(() => setLoading(false));
	};

	const handleSuccess = async () => {
		uploadUserPhotos();
	};

	const handleCancel = () => {
		resetToOriginal();
	};

	const handleResetImage = () => {
		cancelImageChange(ImageType.HeaderImage);
		cancelImageChange(ImageType.ProfileImage);
		setNewHeaderPhoto(false);
		setNewProfilePhoto(false);
		setImgEditing(false);
	};

	const resetToOriginal = () => {
		// useDetailReset
		setUserDetails(userDetailsInitial);
		//dreamJob Reset
		setDreamJob(dreamJobInitial!);
		//skillReset
		setSkills(skillsInitial);
		//language
		setLanguageSkills(languageSkillsInitial);

		//aboutMe reset
		setAboutMe(aboutMeInital);
		setCertificates(certificatesInitial);
		setEducations(educationsInitial);
		setWorkExperiences(workExperiencesInitial);
		setVideoSource(aboutMeInital?.filePath);

		setIsEditing(false);
	};

	const handleToggleChange = async (e: any) => {
		const isChecked = e.target.checked;
		setUserDetails((prevDetails) => ({ ...prevDetails!, isPublic: isChecked }));
	};

	function formatDate(dateString: string) {
		const date = new Date(dateString);
		const options: Intl.DateTimeFormatOptions = {
			year: "numeric",
			month: "long",
			day: "numeric",
		};
		return date.toLocaleDateString("en-US", options);
	}

	return (
		<>
			<Helmet>
				<title>
					{t("myProfile")} | {t("reCREWter")}
				</title>
			</Helmet>

			{/* wrapper start */}
			<div className="wrapper">
				{/* sidebar start */}
				<Sidebar />
				{/* sidebar end */}

				{/* main start */}
				<main>
					{/* header start */}
					<Header />
					{/* header end */}

					{/* profile wrapper start */}
					<div className="inner-wrapper profile-wrapper">
						{/* left start */}
						<div className={`left ${isEditing ? "profile-full-screen" : ""}`}>
							{/* user info start  */}
							<div className="user-info card">
								<Tooltip
									arrow
									title={t("recommendedMinHeader")}
									disableHoverListener={!isEditing}
									classes={{ popper: "resolution-tooltip" }}
								>
									<div
										className={`thumbnail ${isEditing ? "backgroundImg" : ""}`}
										style={{
											backgroundImage: `url(${images1[ImageType.HeaderImage]})`,
										}}
										onClick={() =>
											isEditing &&
											openUploadDialog(
												setNewHeaderPhoto,
												setHeaderFile,
												setImage.bind(null, ImageType.HeaderImage),
												ImageType.HeaderImage
											)
										}
									></div>
								</Tooltip>
								{/* content start */}
								<div className="content">
									<Tooltip
										arrow
										title={t("recommendedProfile")}
										disableHoverListener={!isEditing}
										classes={{ popper: "profile-img-tooltip" }}
									>
										<img
											src={images1[ImageType.ProfileImage]}
											alt={`${userDetails?.firstName} ${userDetails?.lastName}`}
											className={isEditing ? "profile-img" : ""}
											onClick={() => {
												isEditing &&
													openUploadDialog(
														setNewProfilePhoto,
														setProfileFile,
														setImage.bind(null, ImageType.ProfileImage),
														ImageType.ProfileImage
													);
											}}
										/>
									</Tooltip>
									{role === UserRoles.Employee && videoSource && (
										<div
											className="view-video"
											onClick={() => setViewVideoModal(true)}
										>
											<img src={images.PlayIcon} alt={t("Video")} />
										</div>
									)}
									{/* user details start */}
									<div className="user-details">
										<div className="top">
											<div className="user-name">
												<h4>{getName(claims)}</h4>
												{/* {checkRoles(claims)} */}
												<p>
													{checkRole(claims, UserRoles.Admin)
														? t("administrator")
														: checkRole(claims, UserRoles.Recrewter)
															? t("company")
															: checkRole(claims, UserRoles.Employee)
																? t("talentProfessional")
																: ""}
												</p>
												{userDetails?.nationality && (
													<span className="location">
														<img src={images.Location} alt="Location" />
														<span>
															{userDetails?.state
																? `${t(enumToString(State, userDetails?.state))}, `
																: ""}

															{t(
																enumToString(
																	Country,
																	Number(userDetails?.nationality ?? 0)
																)
															)}
														</span>
													</span>
												)}
											</div>
											{!isEditing && (
												<Button
													variant="outlined"
													size="small"
													title={t("editProfile")}
													onClick={() => {
														setIsEditing(true);
													}}
													className="editButton"
												>
													<img src={images.Edit} alt="Edit" />
													<span>{t("editProfile")}</span>
												</Button>
											)}
											{isImgEditing && (
												<Grid
													size={{ sm: 6, md: 4, xs: 12 }}
													sx={{
														display: "flex",
														justifyContent: "flex-end",
														gap: "15px",
													}}
													className="buttonGrid"
												>
													<Button
														hidden={!isEditing}
														type="button"
														onClick={handleResetImage}
														variant="outlined"
														disabled={!isImgEditing}
													>
														{t("revertImages")}
													</Button>
													<Button
														hidden={!isEditing}
														type="button"
														onClick={uploadUserPhotos}
														color="primary"
														variant="contained"
														disabled={!isImgEditing}
													>
														{t("saveImages")}
													</Button>
												</Grid>
											)}
										</div>
										<div className="bottom">
											<div className="social-links">
												{userDetails?.xing && (
													<RouterLink
														to={formatUrlLink(userDetails?.xing)}
														target="_blank"
													>
														<IconButton title="Xing">
															<img src={images.Xing} alt="Xing" />
														</IconButton>
													</RouterLink>
												)}
												{userDetails?.linkedIn && (
													<RouterLink
														to={formatUrlLink(userDetails?.linkedIn)}
														target="_blank"
													>
														<IconButton title={t("linkedin")}>
															<img src={images.Linkedin2} alt={t("linkedin")} />
														</IconButton>
													</RouterLink>
												)}
												{userDetails?.website && (
													<RouterLink
														to={formatUrlLink(userDetails?.website)}
														target="_blank"
													>
														<IconButton title={t("website")}>
															<img src={images.Globe} alt={t("website")} />
														</IconButton>
													</RouterLink>
												)}
											</div>
											<div className="btn-group">
												{userDetailsInitial?.email !== getEmail(claims) && (
													<Button
														variant="outlined"
														title={t("chatNow")}
														to="/chat"
														component={RouterLink}
													>
														<img src={images.Chat} alt="Chat" />
														<span>{t("chatNow")}</span>
													</Button>
												)}
											</div>
										</div>
										{checkRoles(claims, [UserRoles.Employee]) && (
											<div className="public-toggle">
												<FormControlLabel
													control={
														<Switch
															inputRef={toggleRef}
															checked={userDetails?.isPublic === true}
															onChange={handleToggleChange}
															color="primary"
															id="flexSwitchCheckDefault"
															disabled={!isEditing}
														/>
													}
													label={
														userDetails?.isPublic === true
															? t("profileOnlyRecrewter")
															: t("profileOnlyEmployee")
													}
													labelPlacement="end"
												/>
											</div>
										)}
									</div>
									{/* user details start */}
								</div>
								{/* content end */}
							</div>
							{/* user info end  */}

							{/* profile steps start */}
							<ToggleButtonGroup
								value={alignment}
								exclusive
								onChange={handleAlignment}
								aria-label="text alignment"
								className="profile-steps"
							>
								<ToggleButton
									value={"basicInformation"}
									aria-label={t("basicInformation")}
									title={t("basicInformation")}
									component={Link}
								>
									<img
										src={
											alignment === "basicInformation"
												? images.Information
												: images.InformationActive
										}
										alt={t("basicInformation")}
									/>
									<span>{t("basicInformation")}</span>
								</ToggleButton>
								<ToggleButton
									value={
										role === UserRoles.Employee
											? "myDreamJob"
											: "idealCandidate"
									}
									aria-label={
										role === UserRoles.Employee
											? t("myDreamJob")
											: t("idealCandidate")
									}
									title={
										role === UserRoles.Employee
											? t("myDreamJob")
											: t("idealCandidate")
									}
									component={Link}
								>
									<img
										src={
											alignment === "myDreamJob" ||
											alignment === "idealCandidate"
												? images.JobTickWhite
												: images.JobTick
										}
										alt={
											role === UserRoles.Employee
												? t("myDreamJob")
												: t("idealCandidate")
										}
									/>
									<span>
										{role === UserRoles.Employee
											? t("myDreamJob")
											: t("idealCandidate")}
									</span>
								</ToggleButton>
								{role === UserRoles.Employee && (
									<ToggleButton
										value={"myCv"}
										aria-label={t("myCv")}
										title={t("myCv")}
										component={Link}
									>
										<img
											src={
												alignment === "myCv"
													? images.MyCVWhite
													: images.MyCVActive
											}
											alt={t("myCv")}
										/>
										<span>{t("myCv")}</span>
									</ToggleButton>
								)}
							</ToggleButtonGroup>
							{/* profile steps end */}
							{isEditing ? (
								<EditMyProfile
									userDetails={userDetails}
									dreamJob={dreamJob}
									handleAlignment={handleAlignment}
									alignment={alignment}
									setIsEditing={setIsEditing}
									isEditing={isEditing}
									setDreamJob={setDreamJob}
									setBasicDetails={setUserDetails}
									onUserProfileCompleted={handleUserProfileCompleted}
									setUserDetailsInitial={setUserDetailsInitial}
									aboutMeData={aboutMe}
									setAboutMeData={setAboutMe}
									setAboutMeInitialData={setAboutMeInitial}
									cvVideo={videoSource}
									setCvVideo={setVideoSource}
									setDreamJobInitial={setDreamJobInitial}
									languageSkills={languageSkills}
									setLanguageSkills={setLanguageSkills}
									setLanguageSkillsInitial={setLanguageSkillsInitial}
									setSkillsInitial={setSkillsInitial}
									skills={skills}
									setSkills={setSkills}
									workExperiences={workExperiences}
									setWorkExperiences={setWorkExperiences}
									setWorkExperiencesInitial={setWorkExperiencesInitial}
									setEducationsInitial={setEducationsInitial}
									educations={educations}
									setEducations={setEducations}
									certificates={certificates}
									setCertificates={setCertificates}
									setCertificatesInitial={setCertificatesInitial}
									setIdealCandidate={setIdealCandidate}
									idealCandidate={idealCandidate}
									setInitialIdealCandidate={setInitialIdealCandidate}
									handleSuccess={handleSuccess}
									handleScroll={handleScroll}
									handleCancel={handleCancel}
									profileCompleted={profileCompleted}
								/>
							) : (
								<ViewProfile
									userDetails={userDetails}
									dreamJob={dreamJob}
									aboutMe={aboutMe}
									cvVideo={videoSource}
									languageSkills={languageSkills}
									skills={skills}
									idealCandidate={idealCandidate}
									workExperiences={workExperiences}
									certificates={certificates}
									educations={educations}
								/>
							)}
						</div>
						{/* left end */}

						{/* right start */}
						{!isEditing && (
							<div className="right">
								<MatchingBaseDetailCard
									jobProperties={
										role === UserRoles.Employee ? dreamJob : idealCandidate
									}
									title={
										role === UserRoles.Employee
											? "myDreamJob"
											: "idealCandidate"
									}
								/>
								<div className="card personal-information">
									<h6>{t("myPersonalInfo")}</h6>
									<ul>
										<li>
											<div className="icon">
												<img src={images.MyProfileActive} alt="User" />
											</div>
											<span>
												<small>{t("fullName")}</small>
												<span>{getName(claims)} </span>
											</span>
										</li>
										<li>
											<div className="icon">
												<img src={images.Calendar} alt="User" />
											</div>
											<span>
												<small>{t("birthday")}</small>
												<span>{formatDate(userDetails?.dateOfBirth)} </span>
											</span>
										</li>
										<li>
											<div className="icon">
												<img src={images.Globe} alt="User" />
											</div>
											<span>
												<small>{t("nationality")}</small>
												<span>
													{userDetails?.nationality
														? t(
																enumToString(
																	Country,
																	Number(userDetails?.nationality)
																)
															)
														: "-"}
												</span>
											</span>
										</li>
										<li>
											<div className="icon">
												<img src={images.Location} alt="User" />
											</div>
											<span>
												<small>
													{Country.Switzerland.toString() ===
													userDetails?.nationality
														? t("canton")
														: t("state")}
												</small>
												<span>
													{userDetails?.state
														? t(enumToString(State, userDetails?.state))
														: "-"}
												</span>
											</span>
										</li>
										{Country.Switzerland.toString() !==
											userDetails?.nationality && (
											<li>
												<div className="icon">
													<img src={images.ResidencePermit} alt="User" />
												</div>
												<span>
													<small>{t("residencePermit")}</small>
													<span>
														{userDetails?.residencePermit !== ""
															? userDetails?.residencePermit
															: "-"}
													</span>
												</span>
											</li>
										)}
										<li>
											<div className="icon">
												<img src={images.Email} alt="Email" />
											</div>
											<span>
												<small>{t("email")}</small>
												<span>
													{userDetails?.email ? (
														<Link
															href={`mailto:${userDetails.email}`}
															target="_blank"
														>
															{userDetails.email}
														</Link>
													) : (
														"-"
													)}
												</span>
											</span>
										</li>
										<li>
											<div className="icon">
												<img src={images.Call} alt="Call" />
											</div>
											<span>
												<small>{t("phoneNumber")}</small>
												<span>
													{userDetails?.phoneNumber === ""
														? "-"
														: userDetails?.phoneNumber}
												</span>
											</span>
										</li>
										<li>
											<div className="icon">
												<img src={images.Call} alt="Call" />
											</div>
											<span>
												<small>{t("mobileNumber")}</small>
												<span>
													{userDetails?.mobileNumber === ""
														? "-"
														: userDetails?.mobileNumber}{" "}
												</span>
											</span>
										</li>
										<li>
											<div className="icon">
												<img src={images.LinkedinPrimary} alt="Linkedin" />
											</div>
											<span>
												<small>{t("linkedin")}</small>
												<span>
													{userDetails?.linkedIn ? (
														<Link
															target="_blank"
															href={formatUrlLink(userDetails?.linkedIn)}
														>
															{userDetails.linkedIn}
														</Link>
													) : (
														"-"
													)}
												</span>
											</span>
										</li>
										<li>
											<div className="icon">
												<img src={images.JobsActive} alt="Job" />
											</div>
											<span>
												<small>{t("xing")}</small>
												<span>
													{userDetails?.xing ? (
														<Link
															target="_blank"
															href={formatUrlLink(userDetails?.xing)}
														>
															{userDetails.xing}
														</Link>
													) : (
														"-"
													)}
												</span>
											</span>
										</li>
										<li>
											<div className="icon">
												<img src={images.Globe} alt="Globe" />
											</div>
											<span>
												<small>{t("website")}</small>
												<span>
													{userDetails?.website ? (
														<Link
															target="_blank"
															href={formatUrlLink(userDetails?.website)}
														>
															{userDetails.website}
														</Link>
													) : (
														"-"
													)}
												</span>
											</span>
										</li>
									</ul>
								</div>
							</div>
						)}
						{/* right end */}
					</div>
					{/* profile wrapper end */}
				</main>
				{/* main end */}
			</div>
			{/* wrapper end */}

			{missingData ? (
				// Check for missing data based on role and show appropriate modal
				role === UserRoles.Employee ? (
					!profileCompleted && (
						<ModalContent
							size="lg"
							show={missingData}
							children={<>{t("noPageChangeMissingDataProfile")}</>}
							onHideCallbackSecondary={() => setMissingData(false)}
							onHideCallbackPrimary={() => setMissingData(false)}
							title={t("missingData")}
							primaryButton={t("ok")}
							onClose={() => setMissingData(false)}
						/>
					)
				) : role === UserRoles.Recrewter ? (
					!profileCompleted && !companyProfileCompleted ? (
						<ModalContent
							size="lg"
							show={missingData}
							children={
								<>{t("noPageChangeMissingDataProfileAndCompanyProfile")}</>
							}
							onHideCallbackSecondary={() => setMissingData(false)}
							onHideCallbackPrimary={() => setMissingData(false)}
							title={t("missingData")}
							primaryButton={t("ok")}
							onClose={() => setMissingData(false)}
						/>
					) : !profileCompleted ? (
						<ModalContent
							size="lg"
							show={missingData}
							children={<>{t("justProfileLeft")}</>}
							onHideCallbackSecondary={() => setMissingData(false)}
							onHideCallbackPrimary={() => setMissingData(false)}
							title={t("missingData")}
							primaryButton={t("ok")}
							onClose={() => setMissingData(false)}
						/>
					) : !companyProfileCompleted ? (
						<ModalContent
							size="lg"
							show={missingData}
							children={<>{t("justCompanyProfileLeft")}</>}
							onHideCallbackSecondary={() => setMissingData(false)}
							onHideCallbackPrimary={() => {
								setMissingData(false);
								navigate("/company");
							}}
							title={t("missingData")}
							primaryButton={t("ok")}
							onClose={() => setMissingData(false)}
						/>
					) : null
				) : null
			) : (
				<></>
			)}

			{showCongratsModal && (
				<ModalContent
					size="lg"
					show={showCongratsModal}
					children={<>{t("congratulationsProfileCompleted")}</>}
					onHideCallbackPrimary={() => setShowCongratsModal(false)}
					title={t("congratulations")}
					primaryButton={t("ok")}
				/>
			)}

			{showCompleteOtherProfileModal && (
				<ModalContent
					size="lg"
					show={showCompleteOtherProfileModal}
					children={<>{t("justProfileLeft")}</>}
					onHideCallbackPrimary={() => setShowCompleteOtherProfileModal(false)}
					title={t("missingData")}
					primaryButton={t("ok")}
				/>
			)}
			{viewVideoModal && (
				<ModalContent
					size="md"
					onClose={() => setViewVideoModal(false)}
					show={viewVideoModal}
					children={
						<>
							<div className="video-modal">
								<video
									className="VideoInput_video mb-2"
									width="730px"
									controls
									src={videoSource}
								/>
							</div>
						</>
					}
					onHideCallbackPrimary={() => setViewVideoModal(false)}
					title={t("profileVideo")}
				/>
			)}
		</>
	);
};

export default MyProfile;
