import React, { useContext, useEffect } from "react";
import {
	Button,
	Container,
	ToggleButton,
	ToggleButtonGroup,
} from "@mui/material";
import { Helmet } from "react-helmet";
import images from "../../assets/images";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
	FeedbackMode,
	UserRoles,
} from "../../config/Types/GeneralEnumDefinitions";
import AuthenticationContext from "../../components/auth/AuthenticationContext";
import { exchangeLoginTokenAPI, ssoRegisterAPI } from "../../core/apiFunctions";
import { getClaims, saveToken } from "../../core/handleJWT";
import { getRoleId } from "../../core/claimFunctions";
import useFeedback from "../../utils/useFeedback";
import i18n from "../../translations/i18n";

export const Sso = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { t } = useTranslation();
	const { showError } = useFeedback();
	const { update } = useContext(AuthenticationContext);
	const [alignment, setAlignment] = React.useState<UserRoles | null>(null);

	const queryParams = new URLSearchParams(location.search);
	const email = queryParams.get("email") || "";
	const ssoProvider = queryParams.get("provider") || "";
	const role = queryParams.get("role") || "";
	const errorsFromQuery = queryParams.get("error") || "";

	useEffect(() => {
		i18n.changeLanguage(navigator.language.includes("de") ? "de" : "en");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (errorsFromQuery) {
			navigate("/auth", {
				state: {
					error: t("userExistWithoutSso"),
				},
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [errorsFromQuery, navigate, email]);

	useEffect(() => {
		async function handleAutoLogin() {
			if (ssoProvider && role !== "none") {
				try {
					const response = await exchangeLoginTokenAPI();

					saveToken(response.data);
					var claims = getClaims();
					update(claims);
					var userRole = getRoleId(claims);

					if (
						userRole === UserRoles.Recrewter ||
						userRole === UserRoles.Employee
					) {
						navigate("/dashboard");
					} else if (userRole === UserRoles.Admin) {
						navigate("/profile");
					} else {
						navigate("/auth", { state: { error: t("invalidRole") } });
					}
				} catch (error: any) {
					navigate("/auth", {
						state: { error: error.response?.data || t("loginFailed") },
					});
				}
			}
		}
		handleAutoLogin();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ssoProvider, role, navigate, t]);

	const handleAlignment = (
		event: React.MouseEvent<HTMLElement>,
		newAlignment: UserRoles | null
	) => {
		setAlignment(newAlignment);
	};

	const handleSend = async () => {
		try {
			if (!alignment) {
				showError(t("missingRole"));
				return;
			}

			if (ssoProvider) {
				await ssoRegisterAPI(alignment);
				const response = (await exchangeLoginTokenAPI()).data;
				saveToken(response);
				update(getClaims());
				navigate("/dashboard");
			}
		} catch (error: any) {
			showError(
				error.response?.data || t("registrationFailed"),
				FeedbackMode.Error,
				5000
			);
		}
	};

	if (role !== "none") {
		return null;
	}

	return (
		<>
			<Helmet>
				<title>
					{t("register")} | {t("reCREWter")}
				</title>
			</Helmet>
			<div className="auth-wrapper">
				<Container maxWidth="lg">
					<div className="inner">
						<div className="left">
							<div className="content">
								<img
									src={images.Ellipse1}
									className="ellipse-one"
									alt="Blur Background"
								/>
								<img
									src={images.Ellipse2}
									className="ellipse-two"
									alt="Blur Background"
								/>
								<img src={images.Logo} className="logo" alt="reCREWter" />
								<h3>{t("pleaseSelectRole")}</h3>
								<span>{email}</span>
							</div>
							<img src={images.Illustration} alt="Illustration" />
						</div>
						<div className="right" style={{}}>
							<span>{t("iAmA")}…</span>
							<ToggleButtonGroup
								value={alignment}
								exclusive
								onChange={handleAlignment}
								aria-label="text alignment"
							>
								<ToggleButton
									value={UserRoles.Employee}
									title={t("talentProfessional")}
								>
									<img src={images.Professional} alt="talentProfessional" />
									<span>{t("talentProfessional")}</span>
								</ToggleButton>
								<ToggleButton
									value={UserRoles.Recrewter}
									title={t("company")}
									className="company-toggle"
								>
									<img src={images.Company} alt="Company" />
									<span>{t("company")}</span>
								</ToggleButton>
							</ToggleButtonGroup>

							<div style={{ display: "flex", gap: "20px" }}>
								<Button
									type="button"
									variant="contained"
									sx={{ backgroundColor: "#75a932" }}
									onClick={() => handleSend()}
								>
									{t("btnSend")}
								</Button>
								<Button
									variant="contained"
									sx={{ backgroundColor: "#a5a5a5" }}
									onClick={() => navigate("/")}
								>
									{t("cancel")}
								</Button>
							</div>
						</div>
					</div>
				</Container>
			</div>
		</>
	);
};
